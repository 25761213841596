import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import FileUpload from "@/view/content/FileUpload/FileUpload.vue";

export default {
  components: { FileUpload },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Group", route: "/group/navigation-menu" },
      { title: "Upload Quote CSV to Dynamics CE" }
    ]);
  },
  methods: {
    createDialogMessage() {
      return "Successfully uploaded file";
    }
  }
};
